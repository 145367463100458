import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/Lightbox"

const RealisatiesPage = ({data}) => (
    <Layout>
      <SEO title="Ruwbouw projecten" />

      <h1>Realisaties ruwbouw in Limburg</h1>

      <p>Een overzicht van onze gerealiseerde ruwbouwwerken regio Limburg.
        M&S bouwwerken heeft ervaring in alle soorten stijlen van klassieke, pastorij tot moderne ruwbouwen.</p>
      <p>Bent u op zoek naar een betrouwbare aannemer? Neem <Link to={"/contact/"}>vrijblijvend contact</Link> op voor een offerte op maat.</p>

      <br />
      <br />


      <h3>Pastorijwoning in As</h3>
      <Lightbox images={data.pastorij.edges} />

    <h3>Pastorijwoning in Oudsbergen</h3>
    <Lightbox images={data.pastorijOudsbergen.edges} />

      <h3>Moderne ruwbouw te Genk (Bret-Gelieren)</h3>
      <Lightbox images={data.modern.edges} />

      <h3>Hedendaagse woning te Genk</h3>
      <Lightbox images={data.hedendaags.edges} />

    </Layout>
  )

export default RealisatiesPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    
  allFile(filter: {absolutePath: {regex: "/modern/"}}) {
    edges {
      node {
        absolutePath
        childImageSharp {
          id
        }
      }
    }
  }
  hedendaags: allFile(filter: {sourceInstanceName: {eq: "hedendaags"}}) {
    edges {
      node {
        childImageSharp {
        sizes(maxWidth: 1800) {
            ...GatsbyImageSharpSizes
          }
        }
          
      }
    }
  }
  pastorijOudsbergen: allFile(filter: {sourceInstanceName: {eq: "pastorijOudsbergen"}}) {
    edges {
      node {
        childImageSharp {
        sizes(maxWidth: 1800) {
            ...GatsbyImageSharpSizes
          }
        }
          
      }
    }
  }
  modern: allFile(filter: {sourceInstanceName: {eq: "modern"}}) {
    edges {
      node {
        childImageSharp {
        sizes(maxWidth: 1800) {
            ...GatsbyImageSharpSizes
          }
        }
          
      }
    }
  }
  pastorij: allFile(filter: {sourceInstanceName: {eq: "pastorij"}}) {
    edges {
      node {
        childImageSharp {
        sizes(maxWidth: 1800) {
            ...GatsbyImageSharpSizes
          }
        }
          
      }
    }
  }
  }
`
